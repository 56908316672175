<template>
  <footer class="footer-main">
    <div class="footer-top bg-gray">
      <div class="container">
        <div
          class="row py-0 py-md-3 my-4 mt-0 border-bottom"
          style="border-color: rgba(214, 233, 252, 0.25) !important"
        >
          <div class="col-md-4">
            <div class="d-flex gap-4 align-items-center">
              <img
                src="../../assets/img/icons/guarantee.svg"
                alt="Hire3x preferred by all"
                width="20"
              />
              <span>Preferred by plenty, trusted by all</span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="d-flex gap-4 align-items-center">
              <img
                src="../../assets/img/icons/support.svg"
                alt="Hire3x across the world"
                width="20"
              />
              <span><strong>Support</strong> teams across the world</span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="d-flex gap-4 align-items-center">
              <img
                src="../../assets/img/icons/secure.svg"
                alt="Hire3x online payment"
                width="20"
              />
              <span><strong>Safe & Secure</strong> online payment</span>
            </div>
          </div>
        </div>

        <div class="row py-0 py-md-3 mt-0">
          <div class="col-md-6">
            <div class="row">
              <div class="col-lg-4 col-md-6 mb-5">
                <a href="#" class="brand-footer"
                  ><img
                    src="https://d33yrtc8qntbdn.cloudfront.net/Hire3xAssests/Logo/SVG/Hire3x_website_footer.svg"
                    alt="Hire3x logo white"
                    width="100"
                /></a>
              </div>
              <div class="col-lg-4 mb-5">
                <strong class="text-uppercase mb-3 d-block fLinkHeading"
                  >Quick Links</strong
                >
                <ul class="list-unstyled pointer">
                  <li><a @click="homePage()">Home</a></li>
                  <li><a @click="contactPage()">Contact us</a></li>
                  <li>
                    <a target="_blank" href="https://app.hire3x.com/"
                      >Go To Hire3X</a
                    >
                  </li>
                  <li><a @click="careerPage()">Careers</a></li>
                </ul>
              </div>
              <div class="col-lg-4 mb-5">
                <strong
                  class="text-uppercase mb-3 d-block fLinkHeading"
                  @click="supportPage()"
                  >Support</strong
                >
                <ul class="list-unstyled pointer">
                  <li><a @click="supportPage()">Documentation</a></li>
                  <li><a @click="supportPagefaq()">FAQ</a></li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="row">
              <div class="col-lg-4 mb-5">
                <strong class="text-uppercase mb-3 d-block fLinkHeading"
                  >Products</strong
                >
                <ul class="list-unstyled pointer">
                  <li><a @click="builderPage()">Resume Builder</a></li>
                  <li>
                    <a @click="recruitmentPage()">Hiring Solution</a>
                  </li>
                  <li>
                    <a @click="assessmentPage()">Talent Assessment</a>
                  </li>
                  <li><a @click="productPage()">Training Solution</a></li>
                </ul>
              </div>
              <div class="col-lg-4 mb-5">
                <strong class="text-uppercase mb-3 d-block fLinkHeading"
                  >Policy</strong
                >
                <ul class="list-unstyled pointer">
                  <li><a @click="goToTerms()">Terms & Conditions</a></li>
                  <li><a @click="privacyPage()">Privacy Policy</a></li>
                  <li><a @click="paymentPage()">Payment / Refunds</a></li>
                </ul>
              </div>
              <div class="col-lg-4 mb-5"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom bg-white">
      <div class="container">
        <div
          class="row footer-bottom justify-content-center align-items-center py-2"
        >
          <div class="col-md-4">
            <ul
              class="list-unstyled social d-flex align-items-center gap-2 mb-0"
            >
              <li>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/hire3x/"
                  ><img
                    src="../../assets/img/linkedin2.svg"
                    alt="Hire3x linkedin account"
                /></a>
              </li>
              <li class="ps-2">
                <a
                  target="_blank"
                  href="https://instagram.com/hire3x?igshid=MzRlODBiNWFlZA=="
                  ><img
                    src="../../assets/img/instagram2.svg"
                    alt="Hire3x instagram account"
                /></a>
              </li>
            </ul>
          </div>
          <div class="col-md-4">
            <div class="d-flex justify-content-center">
              <p class="small copyright mb-0">
                Copyrights &copy; 2024 <a href="/">Hire3x</a> - All rights
                reserved
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="d-flex flex-column align-items-center">
              <div>
                <a href="" class="brand-footer"
                  ><img
                    src="https://d33yrtc8qntbdn.cloudfront.net/Hire3xAssests/Logo/SVG/hire3xlogo.svg"
                    alt="Hire3x footer logo"
                /></a>
              </div>
              <div class="powered-by">
                Powered By SimplifyCV Private Limited
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "FooterPage",
  data() {
    return {};
  },

  methods: {
    careerPage() {
      this.$router.push({
        name: "CareerPage",
      });
    },
    homePage() {
      this.$router.push({
        name: "HomePage",
      });
    },
    contactPage() {
      this.$router.push({
        name: "ContactPage",
      });
    },
    supportPage() {
      this.$router.push({
        name: "SupportPage",
      });
    },
    supportPagefaq() {
      this.$router.push({
        name: "SupportPage",
        query: {
          status: "faq",
        },
      });
    },
    recruitmentPage() {
      this.$router.push({
        name: "RecruitmentPage",
      });
    },
    assessmentPage() {
      this.$router.push({
        name: "AssessmentPage",
      });
    },
    productPage() {
      this.$router.push({
        name: "ProductPage",
      });
    },
    builderPage() {
      this.$router.push({
        name: "BuilderPage",
      });
    },
    paymentPage() {
      this.$router.push({
        name: "PaymentPage",
      });
    },
    privacyPage() {
      this.$router.push({
        name: "PrivacyPage",
      });
    },
    goToTerms() {
      this.$router.push({
        name: "TermsandConditions",
      });
    },
  },
};
</script>
