import {
  request,
  SSOService,
  websiteRequest,
  resumeParser,
  conv,
} from "../utils/request.js";
import axios from "axios";
export const contactUs = (data) => {
  return request({
    url: `contact-us/`,
    method: "post",
    data,
  });
};
export const userLogin = (data, headers) => {
  return SSOService({
    url: `auth/realms/SimplifyCV/protocol/openid-connect/token`,
    method: "post",
    headers: headers,
    data,
  });
};
export const keyCloakLogin = (token) => {
  // return axios.get("https://keynode.simplifycv.com/user-access/",{
  return axios.get("https://keynode.hire3x.com/user-access/", {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getPreference = () => {
  return websiteRequest({
    url: `user-preference/`,
    method: "get",
  });
};

export const getjobDetails = () => {
  return request({
    url: `careers/jobs/`,
    method: "get",
  });
};

export const parseResume = (data) => {
  return resumeParser({
    url: `christ-parser/`,
    method: "post",
    data,
  });
};

export const converter = (data) => {
  return conv({
    // url: "htmltopdf",
    url: "rep/resume-clinic-download/",
    method: "POST",
    responseType: "blob",
    data,
  });
};
