<template>
  <!-- for the below div bind the class bg-e9ecef when the second screen comes -->
  <div class="resCli-maincont" :class="{ 'bg-e9ecef': resumeUpLoaded }">
    <div v-if="loaderStatus" class="rescli-backdrop-blur">
      <Spinner :id="'resumeclinic'" :type="resumeUpLoaded ? 'download' : ''" />
    </div>
    <header>
      <img class="ms-3" src="../assets/ResumeClinic/hire3x.svg" alt="" />
    </header>
    <main
      ref="mobilecontainer"
      @scroll="mobilehandleScroll"
      :style="resumeUpLoaded ? 'top:60px' : ''"
    >
      <template v-if="!resumeUpLoaded">
        <img
          class="top-img"
          src="../assets/ResumeClinic/resumeClinic.svg"
          alt=""
        />
        <div class="top-txt">
          <h1>Resume Clinic</h1>
          <h4>
            Upload your resume and receive AI-powered feedback, fine-tuned by
            industry experts to help you stand out in your career journey.
          </h4>
          <div class="rotating-border">
            <div class="card-content d-flex flex-column align-items-center">
              <img src="../assets/ResumeClinic/ResumeClinic-dp.svg" alt="" />
              <span class="rescli-upload-txt w-75 my-3">
                Click on upload your resume button to get started</span
              >
              <span class="rescli-italic-txt mb-4"
                >PDF & DOCX only, Max 5 MB file size</span
              >
              <b-button @click="uploadRes" class="rescli-upload-btn"
                >Upload Your Resume</b-button
              >
              <input
                type="file"
                ref="fileInput"
                style="display: none"
                @change="handleFileUpload"
              />
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <section
          id="parsed-resume"
          ref="parsedResume"
          class="resclinic-sub-container m-2"
        >
          <section class="rc-sec-top-container">
            <section
              class="rec-sec-top-container2 d-flex flex-column align-items-center"
            >
              <div class="text-center position-relative mt-3">
                <img
                  src="https://cvstaticfiles.s3.ap-south-1.amazonaws.com/website/4colorMeter.svg"
                  alt=""
                />
                <img
                  class="position-absolute"
                  src="https://cvstaticfiles.s3.ap-south-1.amazonaws.com/website/blackDial.svg"
                  alt=""
                  :style="{
                    transform: 'rotate(' + overallScore * 18 + 'deg)',
                  }"
                  :class="getMeterClass"
                />
              </div>

              <div class="meter-txt text-center my-4">
                {{ overAllRating }}
              </div>
            </section>
            <section class="pb-2 w-100 pl-65 pl-60">
              <div class="d-flex align-items-center w-100">
                <div class="w-50 d-flex align-items-center">
                  <img
                    v-if="keyStates.personal_info == 'green'"
                    class="jt-1"
                    src="https://cvstaticfiles.s3.ap-south-1.amazonaws.com/website/greentick.svg"
                    alt=""
                  />
                  <img
                    v-else
                    class="jt-1"
                    src="https://cvstaticfiles.s3.ap-south-1.amazonaws.com/website/redcross.svg"
                    alt=""
                  />
                  <span class="rc-points-txt">Personal Info</span>
                </div>
                <div class="w-50 ms-2 d-flex align-items-center">
                  <img
                    v-if="keyStates.education == 'green'"
                    class="jt-1"
                    src="https://cvstaticfiles.s3.ap-south-1.amazonaws.com/website/greentick.svg"
                    alt=""
                  />
                  <img
                    v-else
                    class="jt-1"
                    src="https://cvstaticfiles.s3.ap-south-1.amazonaws.com/website/redcross.svg"
                    alt=""
                  />
                  <span class="rc-points-txt">Education</span>
                </div>
              </div>
              <div class="d-flex align-items-center mt-2">
                <div class="w-50 d-flex align-items-center">
                  <img
                    v-if="keyStates.project == 'green'"
                    class="jt-1"
                    src="https://cvstaticfiles.s3.ap-south-1.amazonaws.com/website/greentick.svg"
                    alt=""
                  />
                  <img
                    v-else
                    class="jt-1"
                    src="https://cvstaticfiles.s3.ap-south-1.amazonaws.com/website/redcross.svg"
                    alt=""
                  />
                  <span class="rc-points-txt">Project</span>
                </div>
                <div class="w-50 ms-2 d-flex align-items-center">
                  <img
                    v-if="keyStates.skill == 'green'"
                    class="jt-1"
                    src="https://cvstaticfiles.s3.ap-south-1.amazonaws.com/website/greentick.svg"
                    alt=""
                  />
                  <img
                    v-else
                    class="jt-1"
                    src="https://cvstaticfiles.s3.ap-south-1.amazonaws.com/website/redcross.svg"
                    alt=""
                  />
                  <span class="rc-points-txt">Skills</span>
                </div>
              </div>
              <div class="d-flex align-items-center mt-2">
                <div class="w-50 d-flex align-items-center">
                  <img
                    v-if="keyStates.experience == 'green'"
                    class="jt-1"
                    src="https://cvstaticfiles.s3.ap-south-1.amazonaws.com/website/greentick.svg"
                    alt=""
                  />
                  <img
                    v-else
                    class="jt-1"
                    src="https://cvstaticfiles.s3.ap-south-1.amazonaws.com/website/redcross.svg"
                    alt=""
                  />
                  <span class="rc-points-txt">Experience</span>
                </div>
                <div class="w-50 ms-2 d-flex align-items-center">
                  <img
                    v-if="keyStates.template == 'green'"
                    class="jt-1"
                    src="https://cvstaticfiles.s3.ap-south-1.amazonaws.com/website/greentick.svg"
                    alt=""
                  />
                  <img
                    v-else
                    class="jt-1"
                    src="https://cvstaticfiles.s3.ap-south-1.amazonaws.com/website/redcross.svg"
                    alt=""
                  />
                  <span class="rc-points-txt">Template</span>
                </div>
              </div>

              <!-- <b-row class="mt-2">
                <b-col class="px-0"> </b-col>
                <b-col class="px-0 ms-2 d-flex align-items-center"> </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col class="px-0"> </b-col>
                <b-col class="px-0 ms-2 d-flex align-items-center"> </b-col>
              </b-row> -->
            </section>
          </section>
          <section class="px-2 rsc-cards-container">
            <div class="breief-txt my-3">Brief Overview :</div>
            <div class="resumeclinic-card">
              <!--  when data is empty bind mb-2 for b-card  -->
              <!-- personal info -->
              <b-card class="position-relative">
                <div class="resc-card-blueline"></div>
                <div class="rsc-mainhead">Personal Info</div>
                <section
                  class="d-flex align-items-center justify-content-between w-100"
                >
                  <b-card-text class="mb-0 rsc-subtxt mt-1 w-75">
                    <img
                      v-if="
                        resumeData.personal_info === '-' ||
                        resumeData.personal_info === ''
                      "
                      src="@/assets/ResumeClinic/dash.svg"
                      alt=""
                    />
                    <span v-else> {{ resumeData.personal_info }}</span>
                  </b-card-text>
                  <div class="d-flex justify-content-end w-25">
                    <div :class="ratingText(keyStates['personal_info'])">
                      {{ scoreColor[stateText("personal_info")] }}
                    </div>
                  </div>
                </section>
              </b-card>

              <!-- education info 
                when data is empty bind mb-2 for b-card 
                -->

              <b-card class="position-relative mt-2">
                <div class="resc-card-blueline"></div>
                <div class="rsc-mainhead">Education</div>
                <section class="d-flex align-items-center w-100">
                  <b-card-text class="mb-0 rsc-subtxt mt-1 w-75">
                    <img
                      v-if="
                        resumeData.education === '-' ||
                        resumeData.education === ''
                      "
                      src="@/assets/ResumeClinic/dash.svg"
                      alt=""
                    />
                    <span v-else> {{ resumeData.education }}</span>
                  </b-card-text>
                  <div class="d-flex justify-content-end w-25">
                    <div :class="ratingText(keyStates['education'])">
                      {{ scoreColor[stateText("education")] }}
                    </div>
                  </div>
                </section>
              </b-card>

              <!-- projects -->
              <b-card class="position-relative mt-2">
                <div class="resc-card-blueline"></div>
                <div class="rsc-mainhead">Projects</div>
                <section class="d-flex align-items-center w-100">
                  <b-card-text class="mb-0 rsc-subtxt mt-1 w-75">
                    <img
                      v-if="
                        resumeData.project === '-' || resumeData.project === ''
                      "
                      src="@/assets/ResumeClinic/dash.svg"
                      alt=""
                    />
                    <span v-else> {{ resumeData.project }}</span>
                  </b-card-text>
                  <div class="d-flex justify-content-end w-25">
                    <div :class="ratingText(keyStates['project'])">
                      {{ scoreColor[stateText("project")] }}
                    </div>
                  </div>
                </section>
              </b-card>

              <!-- portfolio -->
              <b-card class="position-relative mt-2">
                <div class="resc-card-blueline"></div>
                <div class="rsc-mainhead">Portfolio</div>
                <section class="d-flex align-items-center w-100">
                  <b-card-text class="mb-0 rsc-subtxt mt-1 w-75">
                    <img
                      v-if="
                        resumeData.portfolio === '-' ||
                        resumeData.portfolio === ''
                      "
                      src="@/assets/ResumeClinic/dash.svg"
                      alt=""
                    />
                    <span v-else> {{ resumeData.portfolio }}</span>
                  </b-card-text>
                  <div class="d-flex justify-content-end w-25">
                    <div :class="ratingText(keyStates['portfolio'])">
                      {{ scoreColor[stateText("portfolio")] }}
                    </div>
                  </div>
                </section>
              </b-card>

              <!-- skills -->
              <b-card class="position-relative mt-2">
                <div class="resc-card-blueline"></div>
                <div class="rsc-mainhead">Skills</div>
                <section class="d-flex align-items-center w-100">
                  <b-card-text class="mb-0 rsc-subtxt mt-1 w-75">
                    <img
                      v-if="resumeData.skill === '-' || resumeData.skill === ''"
                      src="@/assets/ResumeClinic/dash.svg"
                      alt=""
                    />
                    <span v-else> {{ resumeData.skill }}</span>
                  </b-card-text>
                  <div class="d-flex justify-content-end w-25">
                    <div :class="ratingText(keyStates['skill'])">
                      {{ scoreColor[stateText("skill")] }}
                    </div>
                  </div>
                </section>
              </b-card>

              <!-- Experience -->
              <b-card class="position-relative mt-2">
                <div class="resc-card-blueline"></div>
                <div class="rsc-mainhead">Experience</div>
                <section class="d-flex align-items-center w-100">
                  <b-card-text class="mb-0 rsc-subtxt mt-1 w-75">
                    <img
                      v-if="
                        resumeData.experience === '-' ||
                        resumeData.experience === ''
                      "
                      src="@/assets/ResumeClinic/dash.svg"
                      alt=""
                    />
                    <span v-else> {{ resumeData.experience }}</span>
                  </b-card-text>
                  <div class="d-flex justify-content-end w-25">
                    <div :class="ratingText(keyStates['experience'])">
                      {{ scoreColor[stateText("experience")] }}
                    </div>
                  </div>
                </section>
              </b-card>

              <!-- Endeavours -->
              <b-card class="position-relative mt-2">
                <div class="resc-card-blueline"></div>
                <div class="rsc-mainhead">Endeavours</div>
                <section class="d-flex align-items-center w-100">
                  <b-card-text class="mb-0 rsc-subtxt mt-1 w-75">
                    <img
                      v-if="
                        resumeData.endeavours === '-' ||
                        resumeData.endeavours === ''
                      "
                      src="@/assets/ResumeClinic/dash.svg"
                      alt=""
                    />
                    <span v-else> {{ resumeData.endeavours }}</span>
                  </b-card-text>
                  <div class="d-flex justify-content-end w-25">
                    <div :class="ratingText(keyStates['endeavours'])">
                      {{ scoreColor[stateText("endeavours")] }}
                    </div>
                  </div>
                </section>
              </b-card>

              <!-- Template -->
              <b-card class="position-relative mt-2 mb-4">
                <div class="resc-card-blueline"></div>
                <div class="rsc-mainhead">Template</div>
                <section class="d-flex align-items-center w-100">
                  <b-card-text class="mb-0 rsc-subtxt mt-1 w-75">
                    <img
                      v-if="
                        resumeData.template === '-' ||
                        resumeData.template === ''
                      "
                      src="@/assets/ResumeClinic/dash.svg"
                      alt=""
                    />
                    <span v-else> {{ resumeData.template }}</span>
                  </b-card-text>
                  <div class="d-flex justify-content-end w-25">
                    <div :class="ratingText(keyStates['template'])">
                      {{ scoreColor[stateText("template")] }}
                    </div>
                  </div>
                </section>
              </b-card>
            </div>
          </section>
        </section>
        <div
          @mouseenter="showptag = true"
          @mouseleave="showptag = false"
          :class="showptag ? 'extracv-width view-cv-icon' : 'view-cv-icon'"
          class="target d-flex align-items-center justify-content-center px-1"
        >
          <img src="@/assets/ResumeClinic/whiteDownload.svg" alt="" />
          <p class="mb-0 ms-1" @click="downloadHTML" v-if="showptag">
            Download Report
          </p>
        </div>
      </template>
    </main>
  </div>
</template>

<script>
import Spinner from "../components/fixedComponents/Spinner.vue";
import { parseResume, converter } from "@/utils/apiFunction";
export default {
  data() {
    return {
      showptag: false,
      mobilelastScrollTop: 0,
      resumeData: {},
      keyStates: {},
      loaderStatus: false,
      resumeUpLoaded: false,

      overallScore: null,
      scoreColor: {
        red: "Fair",
        green: "Amazing",
        yellow: "Good",
        orange: "Okay",
      },
      reportPayload: {},
    };
  },
  components: {
    Spinner,
  },
  // created() {
  //   this.resumeData = {
  //     personal_info:
  //       "wyuewyruwyiuewyeww iuwyriew yw etrew ywtew wtywtw  wywtr ywtryuewtryet",
  //     education:
  //       "wyuewyruwyiuewyeww iuwyriew yw etrew ywtew wtywtw  wywtr ywtryuewtryet",
  //     project:
  //       "wyuewyruwyiuewyeww iuwyriew yw etrew ywtew wtywtw  wywtr ywtryuewtryet",
  //     portfolio:
  //       "wyuewyruwyiuewyeww iuwyriew yw etrew ywtew wtywtw  wywtr ywtryuewtryet",
  //     skill:
  //       "wyuewyruwyiuewyeww iuwyriew yw etrew ywtew wtywtw  wywtr ywtryuewtryet",
  //     experience:
  //       "wyuewyruwyiuewyeww iuwyriew yw etrew dfgfd dfgfdh fg fdgerg re tre tret rete tre te rtre tt rteteywtew wtywtw  wywtr ywtryuewtryet",
  //     endeavours:
  //       "wyuewyruwyiuewyeww iuwyriew yw fdgfd  etrew ywtew wtywtw ete t ert ewrt ewrt re tre tre t ret rete wywtr ywtryuewtryet",
  //     template:
  //       "wyuewyruwyiuewyeww iuwyriew yw sdgdgfdgfdgfdgfdgfd etrew ywtew wtywtw  wywtr ywtryuewtryet",
  //   };
  //   this.keyStates = {
  //     personal_info: "green",
  //     education: "red",
  //     project: "green",
  //     portfolio: "red",
  //     skill: "orange",
  //     experience: "green",
  //     endeavours: "red",
  //     template: "yellow",
  //   };
  //   this.overallScore = parseInt(46 / 10);
  // },
  computed: {
    overAllRating() {
      if (this.overallScore >= 8) {
        return "Amazing";
      } else if (this.overallScore >= 6) {
        return "Good";
      } else if (this.overallScore >= 4) {
        return "Okay";
      } else {
        return "Fair";
      }
    },
    getMeterClass() {
      const score = parseInt(this.overallScore);
      switch (score) {
        case 1:
          return "dial-rating-one";
        case 2:
          return "dial-rating-two";
        case 3:
          return "dial-rating-three";
        case 4:
          return "dial-rating-four";
        case 5:
          return "dial-rating-five";
        case 6:
          return "dial-rating-six";
        case 7:
          return "dial-rating-seven";
        case 8:
          return "dial-rating-eight";
        case 9:
          return "dial-rating-nine";
        case 10:
          return "dial-rating-ten";
        default:
          return "dial-rating-zero";
      }
    },
  },
  methods: {
    stateText(state) {
      return this.keyStates[`${state}`];
    },
    ratingText(state) {
      return `${state}-box`;
    },

    uploadRes() {
      this.$refs.fileInput.click();
    },
    async handleFileUpload() {
      this.loaderStatus = true;
      const file = event.target.files[0];
      if (file) {
        const formData = new FormData();
        formData.append("file", file);
        const res = await parseResume(formData);
        if (res.status == 200) {
          console.log(res, "res resdata");
          this.reportPayload = { payload: res.data.payload };
          this.resumeData = res.data.payload.conclusion;
          this.keyStates = res.data.payload.state;
          this.overallScore = parseInt(res.data.payload.score / 25);

          this.loaderStatus = false;
          this.resumeUpLoaded = true;
        }
      }
    },
    async downloadHTML() {
      // const formData = new FormData();

      // const sectionElement = this.$refs.parsedResume;

      try {
        this.loaderStatus = true;

        const res = await converter(this.reportPayload);
        if (res.status === 200) {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: "application/pdf" })
          );

          // window.open(url);
          const link = document.createElement("a");
          link.href = url;
          link.download = "Hire3x Report.pdf";
          link.click();
          // window.open(res.data.link);
          console.log(res, "parsed");
          this.loaderStatus = false;
        } else {
          this.loaderStatus = false;

          console.error("Error:", res);
        }
      } catch (error) {
        this.loaderStatus = false;

        console.error("Failed to convert HTML:", error);
      }
    },

    mobilehandleScroll() {
      const container = this.$refs.mobilecontainer;
      const currentScrollTop = container.scrollTop;

      if (currentScrollTop > this.mobilelastScrollTop) {
        this.showptag = true;
      } else if (currentScrollTop < this.mobilelastScrollTop) {
        // Scrolling up
        this.showptag = false;
      }

      // Update the last scroll position
      this.mobilelastScrollTop = currentScrollTop;
    },
  },
};
</script>

<style>
.view-cv-icon {
  position: fixed;
  z-index: 3;
  bottom: 2px;
  right: 10px;
  width: 48px;
  height: 48px;
  background: #20639b;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.16);
  border-radius: 100px;
}

.view-cv-icon p {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #ffffff;
}

.extracv-width {
  width: 200px;
}
/* .rsc-cards-container{
  height: calc(100dvh - 350px);
  overflow: auto;
} */
.green-box {
  padding: 2px 10px;
  background: rgba(3, 142, 0, 0.1);
  border-radius: 4px;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  font-size: 12px;
  color: #038e00;
}
.red-box {
  padding: 2px 10px;
  background: rgba(215, 52, 44, 0.1);
  border-radius: 4px;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  font-size: 12px;
  color: #d7342c;
}
.green-box {
  padding: 2px 10px;
  background: rgba(3, 142, 0, 0.1);
  border-radius: 4px;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  font-size: 12px;
  color: #038e00;
}
.orange-box {
  padding: 2px 10px;
  background: rgba(215, 52, 44, 0.1);
  border-radius: 4px;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  font-size: 12px;
  color: orange;
}
.yellow-box {
  padding: 2px 10px;
  background: rgba(215, 52, 44, 0.1);
  border-radius: 4px;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  font-size: 12px;
  color: #f9c82b;
}
.rsc-show-ml {
  color: #20639b;
  font-weight: 600;
}
.rsc-subtxt {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #5b5b5b;
}
.rsc-mainhead {
  font-family: "Lato", sans-serif;
  font-weight: 500;
  font-size: 14px;
}
.resumeclinic-card .card {
  background: #fcfcfc;
  box-shadow: 0px 4px 4px rgba(196, 196, 196, 0.5);
  border-radius: 5px;
  width: 100%;
}
.resumeclinic-card .card-body {
  padding: 5px 15px;
}
.resc-card-blueline {
  position: absolute;
  top: 5px;
  left: 5px;
  height: 90%;
  width: 3px;
  background: #20639b;
  border-radius: 1px;
}
.breief-txt {
  font-family: "Lato", sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #545454;
}
.rc-sec-top-container {
  background: #f6f8fb;
  border-radius: 4px;
}
.rc-points-txt {
  font-family: "Montserrat", sans-serif;
  font-style: italic;
  font-weight: 600;
  font-size: 11px;
  color: #333333;
}
.dial-pos {
  position: absolute;
  transform-origin: center;
}

.dial-rating-zero {
  left: 23px;
  bottom: -7px;
}
.dial-rating-one {
  bottom: 0;
  left: 26px;
}
.dial-rating-two {
  bottom: 7px;
  left: 28px;
}
.dial-rating-three {
  bottom: 10px;
  left: 34px;
}
.dial-rating-four {
  bottom: 15px;
  left: 39px;
}
.dial-rating-five {
  bottom: 15px;
}
.dial-rating-six {
  bottom: 15px;
  right: 37px;
}
.dial-rating-seven {
  bottom: 12px;
  right: 32px;
}
.dial-rating-eight {
  bottom: 6px;
  right: 26px;
}
.dial-rating-nine {
  bottom: 2px;
  right: 23px;
}
.dial-rating-ten {
  bottom: -4px;
  right: 21px;
}
.bg-e9ecef {
  background: #e9ecef;
}
.meter-txt {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #333333;
}
.resclinic-sub-container {
  background: #ffffff;
  border-radius: 4px;
  width: 95%;
  /* height: calc(100dvh - 70px); */
}
.resCli-maincont {
  height: 100vh;
  width: 100%;
  /* background-color: #fafbfd; */
}

.resCli-maincont header {
  box-shadow: 0px 4px 4px 0px #00000014;
  background-color: #e5f0ff;
  width: 100%;
  position: fixed;
  top: 0;
  height: 60px;
  display: flex;
  align-items: center;
  z-index: 3;
}
.resCli-maincont main {
  height: calc(100vh - 60px);
  /* position: fixed; */
  top: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1;
}

.resCli-maincont main .top-img {
  /* position: absolute; */
  /* height: 200px; */
  opacity: 0.6;
  top: -55px;
  z-index: 1;
}

.resCli-maincont main h1 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 40px;
  background: linear-gradient(179.21deg, #102133 0.68%, #4c78ce 165.08%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}
.resCli-maincont main h4 {
  text-align: center;
  color: #0f172a;
  font-family: "Satoshi", sans-serif;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
}

.resCli-maincont .top-txt {
  position: absolute;
  z-index: 2;
  padding-inline: 15px;
  /* bottom: 14%; */
  top: 27vh;
}

.rescli-upload-txt {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 18.2px;
  text-align: center;
  color: #595959;
}

.rescli-italic-txt {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 15.6px;
  text-align: center;
  color: #595959;
}

.rescli-upload-btn.btn-secondary {
  background-color: #52a6ff;
  box-shadow: 0px 1px 2px 0px #1018280d;
  border: 1px solid #52a6ff;
  border-radius: 6px;
  font-family: "Inter", sans-serif;
  font-size: 13px;
  font-weight: 800;
  color: #fbfbfb;
  position: absolute;
  bottom: -25px;
}

.rescli-upload-btn.btn-check:checked + .rescli-upload-btn.btn,
.rescli-upload-btn.btn.active,
.rescli-upload-btn.btn.show,
.rescli-upload-btn.btn:first-child:active,
:not(.rescli-upload-btn.btn-check) + .rescli-upload-btn.btn:active,
.rescli-upload-btn.btn:hover {
  color: var(--bs-btn-active-color);
  background-color: #52a6ff;
  border-color: #52a6ff;
}
.rescli-backdrop-blur {
  position: fixed;
  top: 60px;
  width: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.35);
  backdrop-filter: blur(8px);
  z-index: 6;
  display: flex;
  justify-content: center;
}
.rescli-haze-txt {
  position: absolute;
  left: calc((100% - 193px) / 2);
  bottom: 38%;
  z-index: 7;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: #263238;
}
/* rotating border start */

/* Outer container for the card with rotating border */
.rotating-border {
  position: relative;
  width: 100%;
  border-radius: 8px;
  background-size: 300% 300%;
  animation: rotate-gradient 1s linear infinite; /* Rotate the gradient */
  padding: 3px; /* Space for the border */
}

/* Inner card content */
.card-content {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: white;
  padding: 15px;
}

/* Animation for the gradient rotation */
@keyframes rotate-gradient {
  0% {
    background-position: 0% 5%;
    background: linear-gradient(0deg, #20639b, #ffffff, #ffffff);
    /* transition: all 0.3s linear; */
  }
  5% {
    background-position: 5% 10%;
    background: linear-gradient(18deg, #20639b, #ffffff, #ffffff);
    /* transition: all 0.3s linear; */
  }
  10% {
    background-position: 10% 15%;
    background: linear-gradient(36deg, #20639b, #ffffff, #ffffff);
    /* transition: all 0.3s linear; */
  }
  15% {
    background-position: 15% 20%;
    background: linear-gradient(54deg, #20639b, #ffffff, #ffffff);
    /* transition: all 0.3s linear; */
  }
  20% {
    background-position: 20% 25%;
    background: linear-gradient(72deg, #20639b, #ffffff, #ffffff);
    /* transition: all 0.3s linear; */
  }
  25% {
    background-position: 25% 30%;
    background: linear-gradient(90deg, #20639b, #ffffff, #ffffff);
    /* transition: all 0.3s linear; */
  }
  30% {
    background-position: 30% 35%;
    background: linear-gradient(108deg, #20639b, #ffffff, #ffffff);
    /* transition: all 0.3s linear; */
  }
  35% {
    background-position: 35% 40%;
    background: linear-gradient(126deg, #20639b, #ffffff, #ffffff);
    /* transition: all 0.3s linear; */
  }
  40% {
    background-position: 40% 45%;
    background: linear-gradient(144deg, #20639b, #ffffff, #ffffff);
    /* transition: all 0.3s linear; */
  }
  45% {
    background-position: 45% 50%;
    background: linear-gradient(162deg, #20639b, #ffffff, #ffffff);
    /* transition: all 0.3s linear; */
  }
  50% {
    background-position: 50% 55%;
    background: linear-gradient(180deg, #20639b, #ffffff, #ffffff);
    /* transition: all 0.3s linear; */
  }
  55% {
    background-position: 55% 60%;
    background: linear-gradient(198deg, #20639b, #ffffff, #ffffff);
    /* transition: all 0.3s linear; */
  }
  60% {
    background-position: 60% 65%;
    background: linear-gradient(216deg, #20639b, #ffffff, #ffffff);
    /* transition: all 0.3s linear; */
  }
  65% {
    background-position: 65% 70%;
    background: linear-gradient(234deg, #20639b, #ffffff, #ffffff);
    /* transition: all 0.3s linear; */
  }
  70% {
    background-position: 70% 75%;
    background: linear-gradient(252deg, #20639b, #ffffff, #ffffff);
    /* transition: all 0.3s linear; */
  }
  75% {
    background-position: 75% 80%;
    background: linear-gradient(270deg, #20639b, #ffffff, #ffffff);
    /* transition: all 0.3s linear; */
  }
  80% {
    background-position: 80% 85%;
    background: linear-gradient(288deg, #20639b, #ffffff, #ffffff);
    /* transition: all 0.3s linear; */
  }
  85% {
    background-position: 85% 90%;
    background: linear-gradient(306deg, #20639b, #ffffff, #ffffff);
    /* transition: all 0.3s linear; */
  }
  90% {
    background-position: 90% 95%;
    background: linear-gradient(324deg, #20639b, #ffffff, #ffffff);
    /* transition: all 0.3s linear; */
  }
  95% {
    background-position: 95% 100%;
    background: linear-gradient(342deg, #20639b, #ffffff, #ffffff);
    /* transition: all 0.3s linear; */
  }
  100% {
    background-position: 100% 0%;
    background: linear-gradient(360deg, #20639b, #ffffff, #ffffff);
    /* transition: all 0.3s linear; */
  }
}
.ml-6px {
  margin-left: 6px;
}
.pl-60 {
  padding-left: 60px;
}
/* rotating border end */
</style>
