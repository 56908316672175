<template>
  <header
    :class="
      sharePage == true
        ? 'header-main py-2 position-sticky z-1 bg-white shadow-sm border-bottom'
        : 'header-main py-2 position-sticky z-1'
    "
  >
    <div class="container position-relative">
      <b-navbar
        toggleable="lg"
        type="dark"
        variant=""
        class="navbar navbar-expand-lg not-loggedin position-relative"
      >
        <!-- src="https://d33yrtc8qntbdn.cloudfront.net/Hire3xAssests/Logo/SVG/hire3xlogo.svg" -->
        <!-- <b-navbar-brand href="#" @click="homePage()"
          ><img
            src="https://d33yrtc8qntbdn.cloudfront.net/Hire3xAssests/Logo/SVG/Hire3x_Website_Header.svg"
            alt="Hire3x logo"
        /></b-navbar-brand> -->

        <img
          src="https://d33yrtc8qntbdn.cloudfront.net/Hire3xAssests/Logo/SVG/Hire3x_Website_Header.svg"
          height="40px"
        />

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ms-auto">
            <b-nav-item-dropdown text="Products" right class="">
              <b-dropdown-item @click="builderPage()"
                >Resume Builder</b-dropdown-item
              >
              <b-dropdown-item @click="recruitmentPage()"
                >Hiring Solutions</b-dropdown-item
              >
              <b-dropdown-item @click="assessmentPage()"
                >Talent Assessment</b-dropdown-item
              >
              <b-dropdown-item @click="productPage()"
                >Training Solutions</b-dropdown-item
              >
            </b-nav-item-dropdown>
            <b-nav-item href="#" @click="careerPage()">Careers</b-nav-item>
            <b-nav-item href="#" @click="supportPage()">Support</b-nav-item>
            <b-nav-item href="#" @click="contactPage()">Contact</b-nav-item>
            <b-nav-item
              class="btn btn-primary btn-sm btn-login ms-4"
              href="#"
              style="text-transform: none"
              @click="requestDemo()"
              ><strong class="">For Business</strong></b-nav-item
            >
            <b-nav-item
              style="text-transform: none"
              class="btn btn-primary btn-sm btn-login ms-4"
              href="#"
              @click="goToLogin()"
              ><strong class="">Login</strong
              ><span class="ml-2"
                ><img
                  src="../../assets/img/icons/loginwhite.svg"
                  alt="Hire3x login" /></span
            ></b-nav-item>

            <!-- <b-nav-item href="#"
              >Login
              <span
                ><img src="../../assets/img/icons/loginicon.svg" alt="" /></span
            ></b-nav-item> -->
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
  </header>
</template>
<script>
export default {
  name: "HeaderPage",
  data() {
    return {
      route: "",
      sharePage: false,
    };
  },
  watch: {
    "$route.name"(newRouteName) {
      this.routename(newRouteName);
    },
  },
  created() {
    this.routename();
  },
  methods: {
    goToLogin() {
      if (this.$route.name == "HomePage") {
        if (!document.cookie) {
          window.open(`https://app.hire3x.com/`);
          // window.open(`https://simplifymycareer.com`)
        } else {
          this.$store.commit("setLoginPage", "admin");
          this.$store.dispatch("getPref");
        }
      } else if (this.$route.name == "RecruitmentPage") {
        if (!document.cookie) {
          window.open(`https://app.hire3x.com/login/?from=recruitment`);
          // window.open(`https://simplifymycareer.com/login/?from=recruitment`)
        } else {
          this.$store.commit("setLoginPage", "recruitment");
          this.$store.dispatch("setPath");
          // this.$store.dispatch('getPref')
        }
      } else if (this.$route.name == "AssessmentPage") {
        if (!document.cookie) {
          window.open(`https://app.hire3x.com/login/?from=assessment`);
          // window.open(`https://simplifymycareer.com/login/?from=assessment`)
        } else {
          this.$store.commit("setLoginPage", "assessment");
          this.$store.dispatch("setPath");
          // this.$store.dispatch('getPref')
        }
      } else if (this.$route.name == "BuilderPage") {
        if (!document.cookie) {
          // window.open(`http://localhost:8080/login/?from=builder`)
          window.open(`https://app.hire3x.com/login/?from=builder`);
        } else {
          this.$store.commit("setLoginPage", "builder");
          this.$store.dispatch("setPath");
          // this.$store.dispatch('getPref')
        }
      } else {
        if (!document.cookie) {
          window.open(`https://app.hire3x.com/login`);
          // window.open(`https://simplifymycareer.com`)
        } else {
          this.$store.commit("setLoginPage", "admin");
          this.$store.dispatch("getPref");
        }
      }
    },
    careerPage() {
      this.$router.push({
        name: "CareerPage",
      });
    },
    requestDemo() {
      this.$router.push({
        name: "RequestDemo",
      });
    },
    homePage() {
      this.$router.push({
        name: "HomePage",
      });
    },
    supportPage() {
      this.$router.push({
        name: "SupportPage",
      });
    },
    contactPage() {
      this.$router.push({
        name: "ContactPage",
      });
    },
    recruitmentPage() {
      this.$router.push({
        name: "RecruitmentPage",
      });
    },
    assessmentPage() {
      this.$router.push({
        name: "AssessmentPage",
      });
    },
    productPage() {
      this.$router.push({
        name: "ProductPage",
      });
    },
    builderPage() {
      this.$router.push({
        name: "BuilderPage",
      });
    },
    routename() {
      if (this.$route.name == "HomePage") {
        this.sharePage = false;
      } else {
        this.sharePage = true;
      }
    },
  },
};
</script>
