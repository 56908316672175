<template>
  <section
    class="loader"
    :class="{ 'resume-clinic-height': id == 'resumeclinic' }"
  >
    <div v-if="type == 'download'" class="rescli-haze-txt" style="bottom: 33%">
      <transition name="fade" mode="out-in">
        <span :key="dots" class="downloading-text">
          Downloading Report{{ dots }}
        </span>
      </transition>
    </div>
    <div v-else-if="id == 'resumeclinic'" class="rescli-haze-txt">
      <transition name="slide-left">
        <span
          :key="currentPhrase"
          class="phrase"
          :class="
            loaderText[currentIndex] == 'Analysing your experience'
              ? 'phrase-2'
              : ''
          "
          >{{ loaderText[currentIndex] }}</span
        >
      </transition>
    </div>

    <div
      :class="['first-circle', { animate: firstCircleAnimate }]"
      :style="{ background: firstCircleBackground }"
    ></div>
    <div :class="['firstmid-circle', { animate: firstMidCircleAnimate }]"></div>

    <div
      class="position-relative"
      :class="['second-circle', { animate: secondRectangleAnimate }]"
      :style="{ background: secondBgColor }"
    >
      <div class="white-line"></div>
      <div class="white-round"></div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SpinnerComponent",
  data() {
    return {
      firstCircleAnimate: false,
      firstMidCircleAnimate: false,
      midContentAnimate: false,
      secondRectangleAnimate: false,
      firstCircleBackground: "#f0f6ff",
      secondBgColor: "#ebedf0",
      timeouts: [],
      intervals: null,
      dotCount: 0,
      currentIndex: 0,
      loaderText: [
        "Parsing your resume",
        "Analysing your experience",
        "Extracting your skills",
        "Generating Report",
      ],
    };
  },
  props: {
    id: { type: String, default: "" },
    type: { type: String, default: "" },
  },
  computed: {
    currentPhrase() {
      return this.loaderText[this.currentIndex];
    },
    dots() {
      return ".".repeat(this.dotCount); // Creates a string with 1, 2, or 3 dots
    },
  },
  mounted() {
    document.addEventListener("visibilitychange", () => {
      if (document.hidden) {
        this.timeouts.forEach((timeout) => {
          clearTimeout(timeout);
        });
        clearInterval(this.intervals);
      } else {
        this.restartAnimationCycle();
        this.intervals = setInterval(() => {
          this.restartAnimationCycle();
        }, 1500);
      }
    });

    this.restartAnimationCycle();
    this.intervals = setInterval(() => {
      this.restartAnimationCycle();
    }, 1500);
    if (this.id == "resumeclinic") {
      this.startAnimation();
    }
    if (this.type == "download") {
      this.startDotAnimation();
    }
  },
  methods: {
    startDotAnimation() {
      this.dotInterval = setInterval(() => {
        this.dotCount = (this.dotCount % 3) + 1;
      }, 500);
    },
    async triggerAnimationCycle() {
      this.timeouts.push(
        setTimeout(() => {
          this.firstCircleAnimate = true;
          this.firstCircleBackground = "#f0f6ff";
        }, 300)
      );
      this.timeouts.push(
        setTimeout(() => {
          this.firstCircleBackground = "unset";
          this.firstMidCircleAnimate = true;
        }, 700)
      );
      this.timeouts.push(
        setTimeout(() => {
          this.firstCircleBackground = "unset";
          this.secondRectangleAnimate = true;
          this.secondBgColor = "#ebedf0";
        }, 1100)
      );
      this.firstCircleBackground = "#f0f6ff";
    },
    async restartAnimationCycle() {
      this.timeouts = [];
      this.firstCircleAnimate = false;
      this.firstMidCircleAnimate = false;
      this.secondRectangleAnimate = false;

      this.triggerAnimationCycle();
    },
    startAnimation() {
      setInterval(() => {
        // Update the index to show the next phrase
        this.currentIndex = (this.currentIndex + 1) % this.loaderText.length;
      }, 3000); // Change phrase every 3 seconds
    },
  },
};
</script>

<style scoped>
.loader {
  /* width: 100vw; */
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -65px;
}

.first-circle {
  width: 45px;
  height: 45px;
  position: relative;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  transform: rotate(49deg);
  z-index: 8999;
  background: #f0f6ff;
  overflow: hidden;
}

.first-circle::before {
  position: absolute;
  content: "";
  bottom: 0px;
  left: 0px;
  width: 0;
  height: 100%;
  background-color: #68a5ff;
  transition: width 0.4s linear;
  z-index: -1;
}

.first-circle.animate::before {
  width: 100%;
  animation: growWidth 0.4s linear forwards;
}

@keyframes growWidth {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
.firstmid-circle {
  height: 16px;
  width: 18px;
  border-bottom-right-radius: 100%;
  border-top-right-radius: 0px;
  z-index: 500;
  transform: rotate(48deg);
  margin-top: 27px;
  background: #f0f6ff;
  overflow: hidden;
}

.firstmid-circle::before {
  position: absolute;
  content: "";
  bottom: 0px;
  left: 0px;
  width: 0;
  height: 100%;
  background-color: #68a5ff;
  transition: width 0.2s linear;
  z-index: -1;
}

.firstmid-circle.animate::before {
  width: 100%;
  animation: growMidWidth 0.2s linear forwards;
}

@keyframes growMidWidth {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.second-circle {
  width: 41px;
  height: 180px;
  transform: rotate(49deg);
  margin-left: 18px;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 22px;
  position: relative;
  margin-top: -27px;
  background: #ebedf0;
}

.second-circle::before {
  position: absolute;
  content: "";
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 0;
  background-color: #344966;
  transition: height 0.3s linear;
  z-index: -1;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 22px;
}

.second-circle.animate::before {
  height: 100%;
  animation: growsecondWidth 0.3s linear forwards;
}

@keyframes growsecondWidth {
  from {
    height: 0%;
  }
  to {
    height: 100%;
  }
}

.white-round {
  position: absolute;
  bottom: 26px;
  left: 0px;
  height: 21px;
  width: 21px;
  border-radius: 0px 246px 0 0;
  background-color: #fff;
  transform: rotate(89deg);
}

.white-line {
  position: absolute;
  bottom: 0px;
  height: 33px;
  width: 4px;
  background: #fff;
}
.phrase {
  display: inline-block;
  white-space: nowrap;
  position: absolute;
}
.phrase-2 {
  margin-left: -30px;
}

.slide-left-enter-active,
.slide-left-leave-active {
  transition: transform 0.6s ease, opacity 0.6s ease;
}

.slide-left-enter {
  transform: translateX(100%);
  opacity: 0;
}

.slide-left-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}
.resume-clinic-height {
  height: calc(100vh - 60px);
}
</style>
