import axios from "axios";
import jwt_decode from "jwt-decode";
const request = axios.create({
  // baseURL: "https://api-website.simplifycv.com/", // Development
  baseURL: "https://api-website.hire3x.com/", // Development
});
const SSOService = axios.create({
  baseURL:
    // "http://sso.simplifycv.com/", // Development
    // "https://auth.simplifymycareer.com/", // Staging & Development
    "https://auth.hire3x.com/", // Production
});
const conv = axios.create({
  // baseURL: "https://convert.hire3x.com/",
  baseURL: "https://recruitment-api.hire3x.com/",
  // baseURL : "http://localhost:5001/"
});
const websiteRequest = axios.create({
  // baseURL: "https://api-website.simplifymycareer.com",
  baseURL: "https://api-website.hire3x.com/",
});
const resumeParser = axios.create({
  baseURL: "https://resumeparser.hire3x.com",
});
resumeParser.interceptors.request.use(
  (config) => {
    // config.headers["Authorization"] = "Bearer " + getToken();
    config.headers["userID"] = "d343644f-b681-48dd-b2cc-7ae6fbd65f21";

    // config.headers["userID"] = getProcessedToken();
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

websiteRequest.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = "Bearer " + getToken();
    config.headers["userID"] = getProcessedToken();
    // config.headers["userID"] = "e562e893-3ed8-4073-9576-10373bc3e260";

    return config;
  },
  (error) => {
    // Do something with request error
    return error;
  }
);
function getProcessedToken() {
  var decoded = jwt_decode(getToken());
  return decoded.sub;
}
// Get token from localstorage
const getToken = () => {
  let data = document.cookie.split(";");
  for (let i in data) {
    let cur = data[i].split("=");
    if (cur[0].trim() === "accessToken") {
      return cur[1];
    }
  }
  // return localStorage.getItem("vue_token");
};

export { request, SSOService, websiteRequest, resumeParser, conv };
